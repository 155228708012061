import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Server from "./Server"

const App = () => {
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const translate = [
      {
        translattion : "en",
        Title        : "Welcome to Green House Recruitment",
        FullName     : "Full name (Required):",
        DateOfBirth  : "Date of Birth: ",
        Phone        : "Phone",
        Email        : "Email (Required): ",
        SkillNote    : "Skill/Note: ",
        Industry     : "Industry: ",
        SelectAnIndustry : "Select an industry",
        Technology   : "Technology",
        Healthcare   : "Healthcare",
        Finance      : "Finance",
        Education    : "Education",
        Construction : "Construction",
        Welding      : "Welding",
        DieselMechanic: "Diesel Mechanic",
        HVAC         : "HVAC",
        Electrician  : "Electrician",
        Others       : "Others",
        OtherIndustry : "Other Industry",
        Address      : "Address or City: ",
        Submit       : "Submit"
      },
      {
        translattion : "es",
        Title        : "Bienvenido a Reclutamiento de Green House",
        FullName     : "Nombre Completo (Obligatorio):",
        DateOfBirth  : "Fecha de nacimiento: ",
        Phone        : "Teléfono:",
        Email        : "Correo electrónico (Obligatorio): ",
        SkillNote    : "Habilidad/Nota: ",
        Industry     : "Industria: ",
        SelectAnIndustry : "Seleccione una industria",
        Technology   : "Tecnología",
        Healthcare   : "Cuidado de la salud",
        Finance      : "finanzas",
        Education    : "Educación",
        Construction : "Construcción",
        Welding      : "Soldadura",
        DieselMechanic: "Mecánico Diesel",
        HVAC         : "HVAC",
        Electrician  : "Electricista",
        Others       : "Otros",
        OtherIndustry : "Otra Industria",
        Address      : "Dirección o ciudad: ",
        Submit       : "Entregar"
      }
    ];
    const [activeTranslation, setActiveTranslation] = useState(0);
    const [formData, setFormData] = useState({
      fullName: '',
      age: '',
      phone: '',
      email: '',
      skillNote: '',
      industry: '',
      otherIndustry: '',
      addressCity: '',
      translation : ''
    });
    const handleSubmit = async (event) => {
      event.preventDefault();
      try {
        // Replace with your backend endpoint
        const response = await Server.post('http://future-dev.tech/Green-House-Rectruitment/inserting_data', formData, {timeout: 10000});
        if(response.data === "success"){
          setSuccess("Thank you for taking time to fill out the form. We have received your information!");
          setError('');
        }
        // Handle response or reset form here
      } catch (error) {
        setError('Error submitting form: Please Try again later...');
        setSuccess('');
        // Handle error here
      }finally{
        window.location.reload();
      }
    };

    const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };
  return (
    <section>
    <button className='btn btn-info' style={{position: "absolute", top: "1em", right: "1em"}} onClick={()=>{activeTranslation === 0 ? setActiveTranslation(1): setActiveTranslation(0); setFormData({...formData, ["translation"]:translate[activeTranslation].translattion})}}>{activeTranslation === 0 ? "Espanol": "English"}</button>
    <form autoComplete="off" style={{width: "60dvw", margin: "0 auto", padding:"1em", position: "relative"}}>
      <h2 style={{width: "100%", marginLeft: "auto", fontFamily:"sans-serif"}}>{translate[activeTranslation].Title}</h2>
      <div className="form-floating mb-3">
        <input type="text" className="form-control" id="firstName" name="fullName" placeholder="First Name" required value={formData.fullName} onChange={handleChange} />
        <label htmlFor="firstName">{translate[activeTranslation].FullName}</label>
      </div>

      <div className='form-floating mb-3'>
        <input type="date" className='form-control' id="age" name="age" placeholder='i' value={formData.age} onChange={handleChange} />
        <label htmlFor="age">{translate[activeTranslation].DateOfBirth}</label>
      </div>

      <div className='form-floating mb-3'>
        <input type="tel" className='form-control' id="phone" name="phone" required placeholder="i" value={formData.phone} onChange={handleChange}/>
        <label htmlFor="phone">{translate[activeTranslation].Phone}</label>
      </div>

      <div className='form-floating mb-3'>
        <input type="email" className='form-control' id="email" name="email" placeholder='i' required value={formData.email} onChange={handleChange}/>
        <label htmlFor="email">{translate[activeTranslation].Email}</label>
      </div>

      <div className='form-floating mb-3'>
        <input type="text" className='form-control' id="skillNote" name="skillNote" placeholder='i' value={formData.skillNote} onChange={handleChange}/>
        <label htmlFor="skillNote">{translate[activeTranslation].SkillNote}</label>
      </div>
      <section className={formData.industry === 'Others' ?"row": ""}>
      <div className={formData.industry === 'Others'? "form-floating mb-3 col-6" :'form-floating mb-3'}>
        <select className="form-select" id="industry" name="industry" value={formData.industry} onChange={handleChange} required>
          <option value="">{translate[activeTranslation].SelectAnIndustry}</option>
          <option value="Technology">{translate[activeTranslation].Technology}</option>
          <option value="Healthcare">{translate[activeTranslation].Healthcare}</option>
          <option value="Finance">{translate[activeTranslation].Finance}</option>
          <option value="Education">{translate[activeTranslation].Education}</option>
          <option value="Construction">{translate[activeTranslation].Construction}</option>
          <option value="Welding">{translate[activeTranslation].Welding}</option>
          <option value="Disel Mechanic">{translate[activeTranslation].DieselMechanic}</option>
          <option value="HVAC">HVAC</option>
          <option value="Electrician">{translate[activeTranslation].Electrician}</option>
          <option value="Others">{translate[activeTranslation].Others}</option>
        </select>
        <label htmlFor="industry">{translate[activeTranslation].Industry}</label>
      </div>

      {formData.industry === 'Others' && (
        <div className="form-floating mb-3 col-6">
          <input type="text" className="form-control" id="otherIndustry" name="otherIndustry" placeholder="Other Industry" value={formData.otherIndustry} onChange={handleChange} />
          <label htmlFor="otherIndustry">{translate[activeTranslation].OtherIndustry}</label>
        </div>
      )}

      </section>

      <div className="form-floating mb-3">
        <input type="text" id="addressCity" name="addressCity" className='form-control' placeholder='i' value={formData.addressCity} onChange={handleChange}/>
        <label htmlFor="addressCity">{translate[activeTranslation].Address}</label>
      </div>

      <button onClick={handleSubmit} className='btn btn-primary' style={{width: "100%"}}>{translate[activeTranslation].Submit}</button>
      <section className='text-danger mt-2'>
        {error}
      </section>
      <section className='text-success mt-2'>
        {success}
      </section>
    </form>
    </section>
  );
};

export default App;
